<template>
  <div class="app-container">
    <div class="filter-container">
      <el-radio-group v-model="listQuery.order_type" class="filter-item" size="small" @change="getSchoolWithShop">
        <el-radio-button :label="1">外卖</el-radio-button>
        <el-radio-button :label="2">商城</el-radio-button>
      </el-radio-group>
    </div>

    <div class="search-container">
      <div class="search-tree">
        <div class="search-label">选择查询部门</div>
        <div class="search-value">
          <el-tree :data="schoolWithShops" show-checkbox default-expand-all node-key="treeId" ref="tree" @check-change="treeChange"></el-tree>
        </div>
      </div>
      <div class="search-options">
        <div class="search-item">
          <div class="search-label">查询日期</div>
          <div class="search-value">
            <el-date-picker v-model="dateArr" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </div>
<!--        <div class="search-item">-->
<!--          <div class="search-label">订单类型</div>-->
<!--          <div class="search-value">-->
<!--            <el-select v-model="listQuery.order_type" placeholder="订单类型" style="width: 140px" clearable>-->
<!--              <el-option label="食堂美食" :value="1"></el-option>-->
<!--              <el-option label="商城" :value="2"></el-option>-->
<!--              <el-option label="夜猫店" :value="3"></el-option>-->
<!--              <el-option label="零食盒子" :value="4"></el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--        </div>-->
        <el-button type="primary" size="small" @click="handleFilter">搜索</el-button>
        <el-button type="primary" size="small" @click="handleExportExcel">导出</el-button>
      </div>
    </div>

    <div class="total">
      <div class="total-num">
        <span>{{total_pay_count}}</span>
        <span>销售数量合计</span>
      </div>
      <div class="total-price">
        <span>{{total_pay_sum}}</span>
        <span>销售金额合计(元)</span>
      </div>
    </div>

    <el-table id="rebateSetTable" v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row :default-sort="{prop: 'date', order: 'descending'}">
      <el-table-column label="营业日期" min-width="110" align="center" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.date }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易笔数合计" min-width="110" align="center" prop="pay_count" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.pay_count }}</span>
        </template>
      </el-table-column>
      <el-table-column label="退款笔数合计" min-width="110" align="center" prop="refund_count" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.refund_count }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易金额合计(元)" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.pay_money }}</span>
        </template>
      </el-table-column>
      <el-table-column label="餐盒费(元)" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.pack_price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送费(元)" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.send_price }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商家承担配送费" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.shop_bear_send_fee }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户承担配送费" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.user_bear_send_fee }}</span>
        </template>
      </el-table-column>
      <el-table-column label="其他金额合计(元)" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.ledger_money }}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="user.user.type === 'admin'" label="其他金额明细" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-for="(item,key) in scope.row.ledger_log" :key="key">
            <span>{{ item.role_name }} : {{ item.ledger_role_money }} 、</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="结算金额合计(元)" min-width="110" align="center" prop="pay_sum" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.settle_money }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";
  import { guid } from "@/utils/index";
  import FileSaver from "file-saver";
  import XLSX from "xlsx";
  var moment = require("moment");

  export default {
    data() {
      return {
        pickerOptions: {
          shortcuts: [
            {
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              }
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              }
            }
          ]
        },
        shops: [],
        list: null,
        total: null,
        btnLoading: false,
        listLoading: false,
        listQuery: {
          page: 1,
          limit: 10,
          school_id: "",
          shop_ids: [],
          order_type: 1,
          start_date: '',
          end_date: '',
        },
        dateArr: [moment().format("YYYY-MM-DD"),moment().format("YYYY-MM-DD")],
        total_pay_count: 0,
        total_pay_sum: 0,
        schoolWithShops: [],
        treeIds: [],
      };
    },
    created() {
      this.getSchoolWithShop();
    },
    computed: {
      ...mapGetters(["schools", "school_id", "username", "user"]),
      school_name() {
        return this.schools.filter(element => {
          if (this.school_id == element.id) {
            return true
          }
        })[0].school_name
      }
    },
    watch: {
      school_id(newVal, oldVal) {
        this.listQuery.school_id = newVal;
        this.getSchoolWithShop();
      }
    },
    methods: {
      getList() {
        this.listQuery.school_id = this.school_id;
        if (!this.listQuery.school_id) {
          this.$message({
            type: "warning",
            message: "请选择学校"
          });
          return;
        }
        let shop_ids = [];
        // 区分外卖与商城
        if (this.listQuery.order_type === 1) {
          this.schoolWithShops.forEach(element => {
            element.children.forEach(element2 => {
              element2.children.forEach(element3 => {
                if (this.treeIds.indexOf(element3.treeId) > -1) {
                  shop_ids.push(element3.id);
                }
              });
            });
          });
        } else if(this.listQuery.order_type === 2) {
          this.schoolWithShops.forEach(element => {
            element.children.forEach(element2 => {
              if (this.treeIds.indexOf(element2.treeId) > -1) {
                shop_ids.push(element2.id);
              }
            });
          });
        }

        this.listQuery.shop_ids = shop_ids;
        if (this.listQuery.shop_ids.length <= 0) {
          this.$message({
            type: "warning",
            message: "请选择店铺"
          });
          return;
        }
        if (!this.dateArr) {
          this.$message({
            type: "warning",
            message: "请选择日期"
          });
          return;
        }  else {
          this.listQuery.start_date = this.dateArr[0];
          this.listQuery.end_date = this.dateArr[1];
        }
        if (!this.listQuery.order_type) {
          this.$message({
            type: "warning",
            message: "请选择订单类型"
          });
          return;
        }
        this.listLoading = true;
        request({
          url: "/api/backend/finance/day",
          method: "post",
          data: this.listQuery,
          timeout: 6000000,
        }).then(response => {
          this.list = response.data.data;
          this.total_pay_count = response.data.total_pay_count;
          this.total_pay_sum = response.data.total_pay_sum;
          this.listLoading = false;
        });
      },
      getSchoolWithShop() {
        if (!this.school_id) {
          return;
        }
        request({
          url: "/api/backend/school/schoolWithShop",
          method: "get",
          params: {
            school_id: this.school_id,
            type: this.listQuery.order_type
          }
        }).then(response => {
          let data = response.data;
          let schoolWithShops = [];

          // 区分外卖与商城
          if (this.listQuery.order_type === 1) {
            data.forEach(element => {
              let obj = {
                treeId: guid(),
                id: element.id,
                label: element.school_name,
                children: []
              };
              element.canteens.forEach(element2 => {
                let obj2 = {
                  treeId: guid(),
                  id: element2.id,
                  label: element2.canteen_name,
                  children: []
                };
                element2.shops.forEach(element3 => {
                  let obj3 = {
                    treeId: guid(),
                    id: element3.id,
                    label: element3.shop_name,
                    children: []
                  };
                  obj2.children.push(obj3);
                });
                obj.children.push(obj2);
              });
              schoolWithShops.push(obj);
            });
          } else if(this.listQuery.order_type === 2) {
            data.forEach(element => {
              let obj = {
                treeId: guid(),
                id: element.id,
                label: element.school_name,
                children: []
              };
              element.shop_list.forEach(element2 => {
                let obj2 = {
                  treeId: guid(),
                  id: element2.id,
                  label: element2.shop_name,
                  children: []
                };
                obj.children.push(obj2);
              });
              schoolWithShops.push(obj);
            });
          }

          this.schoolWithShops = schoolWithShops;
        });
      },
      treeChange(target, currentCheck, childCheck) {
        let index = this.treeIds.indexOf(target.treeId);
        if (currentCheck) {
          if (index < 0) {
            this.treeIds.push(target.treeId);
          }
        } else {
          if (index > -1) {
            this.treeIds.splice(index, 1);
          }
        }
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      handleExportExcel() {
        this.$nextTick(function() {
          let wb = XLSX.utils.table_to_book(document.querySelector("#rebateSetTable"), {raw:true});
          /* get binary string as output */
          let wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
          });
          try {
            FileSaver.saveAs(
                    new Blob([wbout], { type: "application/octet-stream" }),
                    "营业日结.xlsx"
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
          }
        });
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 6px;
  }
  .total {
    width: 100%;
    height: 120px;
    border: 1px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 10px auto;
    .total-num,
    .total-price {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      span {
        margin-bottom: 10px;
      }
    }
    .total-num {
      border-right: 1px solid #f0f0f0;
    }
  }
  .search-container {
    display: flex;
    /*max-height: 240px;*/
    padding-bottom: 10px;
    box-sizing: border-box;
    margin-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
    .search-tree {
      flex: 0 0 auto;
      width: 300px;
      margin-left: 40px;
      margin-right: 20px;
      .search-label {
        font-size: 14px;
        color: #606266;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .search-value {
        height: 280px;
        overflow: auto;
      }
      ::-webkit-scrollbar{
        width: 5px;
        height: 5px;
      }
      //定义滑块 内阴影+圆角
      ::-webkit-scrollbar-thumb{
        border-radius: 1em;
        background-color: rgba(50,50,50,.3);
      }
      //定义滚动条轨道 内阴影+圆角
      ::-webkit-scrollbar-track{
        border-radius: 1em;
        background-color: rgba(50,50,50,.1);
      }
    }

    .search-options {
      width: 300px;
      flex: 1 0 auto;
      font-size: 14px;
      font-weight: bold;
      color: #606266;
      margin-left: 10px;
      .search-item {
        margin-bottom: 22px;
        .search-label {
          margin-bottom: 8px;
        }
      }
    }
  }

  .report-container {
    width: 800px;
    margin: 0 auto;
    background: #f9f9f9;
    padding: 20px 0;
    color: #414141;
    position: relative;
    .options {
      display: flex;
      justify-content: flex-end;
      .btn-print {
        margin-right: 8px;
      }
    }

    .report-content {
      padding: 25px 25px 0 25px;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      .water-mark {
        width: 160%;
        position: absolute;
        top: 0;
        left: -30%;
        z-index: 100;
        color: #67aef7;
        font-size: 16px;
        line-height: 240px;
        transform: rotate(-45deg) translateY(-100px);
        .mark-name {
          display: inline-block;
          margin-left: 50px;
        }
      }
      .report-header {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #414141;
        margin-bottom: 20px;
        .report-header-date {
          margin-top: 6px;
        }
      }
      .report-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;
        color: #414141;
        .report-label-right {
          min-width: 160px;
        }
      }
      .report-foot {
        margin-top: 10px;
        .foot-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px 0;
          .foot-label,
          .foot-value {
            min-width: 160px;
            font-size: 14px;
            color: #414141;
          }
        }
      }
    }
    .divide-line {
      width: 100%;
      height: 1px;
      background-image: linear-gradient(to right, #414141 0%, #414141 50%, transparent 50%);
      background-size: 8px 1px;
      background-repeat: repeat-x;
      margin-top: 30px;
    }
  }

  #report * {
    font-size: 18px !important;
  }

  .report-table {
    background: #fff;
    border-collapse: collapse;
  }
  .report-table td, .report-table th {
    border: 1px solid #d2d2d2;
  }
  .report-table th {
    font-weight: normal;
  }
  .report-table th, .report-table td {
    width: 11%;
    text-align: center;
    padding: 8px;
  }

</style>
